<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADO DE LOS INDICADORES DEL PROCESO DE {{(datosProceso.nombre).toUpperCase()}}:
                    </span>
                    <hr>
                    <CChartPie :options="optionsEstadistica" class="my-2" :datasets="dataSetsEstadistica" :labels="labelEstadistica" />
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de los indicadores de {{datosProceso.nombre}}</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="4" class="my-2">
                            <b-button block :to="{name: 'Mapa de procesos'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                            </b-button>
                        </b-col>
                        <b-col cols="4" v-if="checkPermissions('002-PRO-MAP','u') == 1" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalModificarProceso = true">
                                <i style="inline-size: auto" class="fas fa-edit fa-3x pb-1"></i><br>Editar proceso
                            </b-button>
                        </b-col>
                        <b-col cols="4" v-if="checkPermissions('002-PRO-MAP','d') == 1" class="my-2">
                            <b-button block size="md" variant="custom" @click="eliminarProceso()">
                                <i style="inline-size: auto" class="fas fa-trash-alt fa-3x pb-1"></i><br>Eliminar proceso
                            </b-button>
                        </b-col>
                        <b-col cols="4" v-if="checkPermissions('002-PRO-IND','c') == 1" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarIndicador = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo indicador
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Indicadores registrados para el área de {{datosProceso.nombre}}</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaIndicadores" :fields="camposIndicadores" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button v-if="checkPermissions('002-PRO-IND','u') == 1" size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalModificarIndicador(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button v-if="checkPermissions('002-PRO-IND','d') == 1" size="sm" class="mr-1 mb-1" @click="eliminarIndicador(param)" v-c-tooltip.hover.click="'Eliminar'" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                                <template #cell(condicion)="data">
                                    <span v-if="data.item.condicion == 1">=</span>
                                    <span v-else-if="data.item.condicion == 2">&gt;=</span>
                                    <span v-else-if="data.item.condicion == 3">&gt;</span>
                                    <span v-else-if="data.item.condicion == 4">&lt;=</span>
                                    <span v-else-if="data.item.condicion == 5">&lt;</span>
                                </template>
                                <template v-slot:cell(valorReal)="data">
                                    <span v-if="data.value == 1">Estático</span>
                                    <span v-else-if="data.value == 2">Fórmula</span>
                                </template>
                                <template v-slot:cell(valorFinalEsperado)="data">
                                    <span v-if="data.item.valorReal == 1">{{data.value}}</span>
                                    <span v-else-if="data.item.valorReal == 2">{{data.value}} / {{data.item.valorFinalEsperado2}}</span>
                                </template>
                                <template v-slot:cell(frecuencia)="data">
                                    <span v-if="data.value == 1">Mensual</span>
                                    <span v-if="data.value == 2">Bimestral</span>
                                    <span v-if="data.value == 3">Trimestral</span>
                                    <span v-if="data.value == 4">Semestral</span>
                                    <span v-if="data.value == 5">Anual</span>
                                </template>
                                <template v-slot:cell(estado)="data">
                                    <b-icon v-if="data.value == 2" icon="circle-fill" variant="success"></b-icon>
                                    <b-icon v-if="data.value == 1" icon="circle-fill" variant="danger"></b-icon>
                                </template>
                                <template #cell(proceso)="param">
                                    {{param.item.proceso | truncate(30)}}
                                </template>
                                <template #cell(descripcionIndicador)="param">
                                    {{param.item.descripcionIndicador | truncate(30)}}
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :show.sync="modalActualizarProceso" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar proceso</span></h6>
            <CButtonClose @click="modalActualizarProceso = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarProceso)">
                <b-row>
                    <b-col lg="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosActualizarProceso.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <b-form-group label="Tipo Proceso: ">
                            <b-form-radio-group small plain v-model="datosActualizarProceso.tipoProceso" :options="radioTipoProceso"></b-form-radio-group>
                            <!-- <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                        </b-form-group>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="descripcion" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarProceso.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="referencias" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Referencias documentales:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarProceso.referenciasDocumentales" :state="getValidationState(validationContext)" placeholder="Ingrese las referencias" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="proveedores principales" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Proveedores Principales:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarProceso.proveedoresPrincipales" :state="getValidationState(validationContext)" placeholder="Ingrese los proveedores" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="proveedores" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Proveedores:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarProceso.proveedores" :state="getValidationState(validationContext)" placeholder="Ingrese los proveedores" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="entradas" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Entradas:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarProceso.entradas" :state="getValidationState(validationContext)" placeholder="Ingrese las entradas" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="responsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Responsable:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarProceso.responsable" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="recursos" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Recursos:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarProceso.recursos" :state="getValidationState(validationContext)" placeholder="Ingrese los recursos" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="clientes" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Clientes:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarProceso.clientes" :state="getValidationState(validationContext)" placeholder="Ingrese los clientes" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="salidas" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Salidas:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarProceso.salidas" :state="getValidationState(validationContext)" placeholder="Ingrese las salidas" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="registros" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Registros:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarProceso.registros" :state="getValidationState(validationContext)" placeholder="Ingrese los registros" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="inspecciones" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Inspecciones:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarProceso.inspecciones" :state="getValidationState(validationContext)" placeholder="Ingrese las inspecciones" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="Indicaciones / Riesgos" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Indicaciones / Riesgos:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarProceso.indicadoresRiesgos" :state="getValidationState(validationContext)" placeholder="Ingrese las indicaciones" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12" class="text-right my-2">
                        <b-button size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarProceso = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalRegistrarIndicador" :closeOnBackdrop="false" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo indicador </span>
            </h6>
            <CButtonClose @click="modalRegistrarIndicador = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarIndicador)">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="proceso" rules="required" v-slot="{errors}">
                            <b-form-group label="Proceso:" class="mb-2">
                                <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosIndicador.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosIndicador.idProceso" :options="comboProcesos" @search:blur="blurProceso">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Valor real: ">
                            <b-form-radio-group small plain v-model="datosIndicador.valorReal" :options="radioValorReal"></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="5">
                        <validation-provider name="descripción del indicador" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Descripción del indicador:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción del indicador" v-model="datosIndicador.descripcionIndicador"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="responsable del indicador" rules="required" v-slot="{errors}">
                            <b-form-group label="Responsable del indicador:" class="mb-2">
                                <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosIndicador.idResponsableIndicador , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosIndicador.idResponsableIndicador" :options="comboCargoResponsable" @search:blur="blurResponsableIndicador">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="responsable de la aprobación" rules="required" v-slot="{errors}">
                            <b-form-group label="Responsable de la aprobación:" class="mb-2">
                                <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosIndicador.idResponsableAprobacion , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosIndicador.idResponsableAprobacion" :options="comboCargoResponsable" @search:blur="blurResponsableAprobacion">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <validation-provider name="fecha de inicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de inicio:" class="mb-2">
                                <b-form-input size="md" type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de inicio" v-model="datosIndicador.fechaInicio"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <validation-provider name="fecha de fin" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de fin:" class="mb-2">
                                <b-form-input size="md" type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de fin" v-model="datosIndicador.fechaFin"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="condición" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Condición:">
                                <b-form-select :state="getValidationState(validationContext)" size="md" value-field="idCondicion" text-field="descripcion" :options="comboCondicion" v-model="datosIndicador.condicion">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="valor final esperado" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Valor final esperado:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.valorFinalEsperado1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.valorFinalEsperado2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>

                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <validation-provider name="tolerancia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tolerancia:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.tolerancia"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="frecuencia" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Frecuencia:">
                                <b-form-select v-on:change="resetDatosFrecuencia()" :state="getValidationState(validationContext)" size="md" value-field="idFrecuencia" text-field="descripcion" :options="comboFrecuencia" v-model="datosIndicador.frecuencia">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <!-- Datos de fechas -->

                    <b-col md="4" v-if="datosIndicador.frecuencia == '1'">
                        <validation-provider name="enero" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Enero:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes1.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes1.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-1" v-model="datosIndicador.datosFrecuencia.mensual.mes1.activo" name="checkbox-1" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '1'">
                        <validation-provider name="febrero" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Febrero:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes2.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes2.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-2" v-model="datosIndicador.datosFrecuencia.mensual.mes2.activo" name="checkbox-2" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '1'">
                        <validation-provider name="marzo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Marzo:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes3.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes3.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-3" v-model="datosIndicador.datosFrecuencia.mensual.mes3.activo" name="checkbox-3" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '1'">
                        <validation-provider name="abril" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Abril:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes4.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes4.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-4" v-model="datosIndicador.datosFrecuencia.mensual.mes4.activo" name="checkbox-4" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '1'">
                        <validation-provider name="mayo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Mayo:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes5.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes5.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-5" v-model="datosIndicador.datosFrecuencia.mensual.mes5.activo" name="checkbox-5" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '1'">
                        <validation-provider name="junio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Junio:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes6.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes6.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-6" v-model="datosIndicador.datosFrecuencia.mensual.mes6.activo" name="checkbox-6" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '1'">
                        <validation-provider name="julio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Julio:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes7.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes7.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-7" v-model="datosIndicador.datosFrecuencia.mensual.mes7.activo" name="checkbox-7" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '1'">
                        <validation-provider name="agosto" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Agosto:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes8.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes8.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-8" v-model="datosIndicador.datosFrecuencia.mensual.mes8.activo" name="checkbox-8" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '1'">
                        <validation-provider name="septiembre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Septiembre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes9.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes9.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-9" v-model="datosIndicador.datosFrecuencia.mensual.mes9.activo" name="checkbox-9" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '1'">
                        <validation-provider name="octubre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Octubre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes10.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes10.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-10" v-model="datosIndicador.datosFrecuencia.mensual.mes10.activo" name="checkbox-10" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '1'">
                        <validation-provider name="noviembre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Noviembre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes11.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes11.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-11" v-model="datosIndicador.datosFrecuencia.mensual.mes11.activo" name="checkbox-11" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '1'">
                        <validation-provider name="diciembre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Diciembre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes12.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.mensual.mes12.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-12" v-model="datosIndicador.datosFrecuencia.mensual.mes12.activo" name="checkbox-12" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosIndicador.frecuencia == '2'">
                        <validation-provider name="1° bimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="1° Bimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre1.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre1.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-13" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre1.activo" name="checkbox-13" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '2'">
                        <validation-provider name="2° bimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="2° Bimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre2.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre2.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-14" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre2.activo" name="checkbox-14" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '2'">
                        <validation-provider name="3° bimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="3° Bimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre3.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre3.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-15" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre3.activo" name="checkbox-15" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '2'">
                        <validation-provider name="4° bimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="4° Bimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre4.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre4.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-16" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre4.activo" name="checkbox-16" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '2'">
                        <validation-provider name="5° bimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="5° Bimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre5.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre5.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-17" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre5.activo" name="checkbox-17" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosIndicador.frecuencia == '2'">
                        <validation-provider name="6° bimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="6° Bimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre6.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre6.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-18" v-model="datosIndicador.datosFrecuencia.bimestral.bimestre6.activo" name="checkbox-18" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosIndicador.frecuencia == '3'">
                        <validation-provider name="1° trimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="1° Trimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.trimestral.trimestre1.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.trimestral.trimestre1.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>

                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-19" v-model="datosIndicador.datosFrecuencia.trimestral.trimestre1.activo" name="checkbox-19" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosIndicador.frecuencia == '3'">
                        <validation-provider name="2° trimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="2° Trimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.trimestral.trimestre2.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.trimestral.trimestre2.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-20" v-model="datosIndicador.datosFrecuencia.trimestral.trimestre2.activo" name="checkbox-20" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosIndicador.frecuencia == '3'">
                        <validation-provider name="3° trimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="3° Trimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.trimestral.trimestre3.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.trimestral.trimestre3.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-21" v-model="datosIndicador.datosFrecuencia.trimestral.trimestre3.activo" name="checkbox-21" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosIndicador.frecuencia == '3'">
                        <validation-provider name="4° trimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="4° Trimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.trimestral.trimestre4.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.trimestral.trimestre4.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-22" v-model="datosIndicador.datosFrecuencia.trimestral.trimestre4.activo" name="checkbox-22" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6" v-if="datosIndicador.frecuencia == '4'">
                        <validation-provider name="1° semestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="1° Semestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.semestral.semestre1.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.semestral.semestre1.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-23" v-model="datosIndicador.datosFrecuencia.semestral.semestre1.activo" name="checkbox-23" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosIndicador.frecuencia == '4'">
                        <validation-provider name="2° semestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="2° Semestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.semestral.semestre2.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.semestral.semestre2.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-24" v-model="datosIndicador.datosFrecuencia.semestral.semestre2.activo" name="checkbox-24" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6" v-if="datosIndicador.frecuencia == '5'">
                        <validation-provider name="anual" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group :label="'Anual - '+anioActual" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.anual.anio.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosIndicador.datosFrecuencia.anual.anio.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-25" v-model="datosIndicador.datosFrecuencia.anual.anio.activo" name="checkbox-25" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observaciones" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosIndicador.observaciones" :state="getValidationState(validationContext)" placeholder="Ingrese la observacion" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarIndicador = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalModificarIndicador" :closeOnBackdrop="false" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Detalle indicador </span>
            </h6>
            <CButtonClose @click="modalModificarIndicador = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(modificarIndicador)">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="proceso_" rules="required" v-slot="{errors}">
                            <b-form-group label="Proceso:" class="mb-2">
                                <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosModificarIndicador.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosModificarIndicador.idProceso" :options="comboProcesos" @search:blur="blurModificarProceso">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Valor real: ">
                            <b-form-radio-group small plain v-model="datosModificarIndicador.valorReal" :options="radioValorReal"></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="5">
                        <validation-provider name="descripción del indicador" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Descripción del indicador:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción del indicador" v-model="datosModificarIndicador.descripcionIndicador"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4">
                        <validation-provider name="responsable del indicador" rules="required" v-slot="{errors}">
                            <b-form-group label="Responsable del indicador:" class="mb-2">
                                <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosModificarIndicador.idResponsableIndicador , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosModificarIndicador.idResponsableIndicador" :options="comboCargoResponsable" @search:blur="blurModificarResponsableIndicador">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="responsable de la aprobación" rules="required" v-slot="{errors}">
                            <b-form-group label="Responsable de la aprobación:" class="mb-2">
                                <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosModificarIndicador.idResponsableAprobacion , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosModificarIndicador.idResponsableAprobacion" :options="comboCargoResponsable" @search:blur="blurModificarResponsableAprobacion">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <validation-provider name="fecha de inicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de inicio:" class="mb-2">
                                <b-form-input size="md" type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de inicio" v-model="datosModificarIndicador.fechaInicio"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <validation-provider name="fecha de fin" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de fin:" class="mb-2">
                                <b-form-input size="md" type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de fin" v-model="datosModificarIndicador.fechaFin"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="condición" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Condición:">
                                <b-form-select :state="getValidationState(validationContext)" size="md" value-field="idCondicion" text-field="descripcion" :options="comboCondicion" v-model="datosModificarIndicador.condicion">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="valor final esperado" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Valor final esperado:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.valorFinalEsperado1"></b-form-input>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.valorFinalEsperado2"></b-form-input>
                                    </template>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>

                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <validation-provider name="tolerancia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tolerancia:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.tolerancia"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="frecuencia" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Frecuencia:">
                                <b-form-select v-on:change="resetDatosFrecuenciaModificar()" :state="getValidationState(validationContext)" size="md" value-field="idFrecuencia" text-field="descripcion" :options="comboFrecuencia" v-model="datosModificarIndicador.frecuencia">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <!-- Datos de fechas -->

                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '1'">
                        <validation-provider name="enero" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Enero:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes1.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes1.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-1" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes1.activo" name="checkbox-1" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>

                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '1'">
                        <validation-provider name="febrero" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Febrero:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes2.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes2.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-2" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes2.activo" name="checkbox-2" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '1'">
                        <validation-provider name="marzo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Marzo:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes3.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes3.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-3" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes3.activo" name="checkbox-3" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '1'">
                        <validation-provider name="abril" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Abril:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes4.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes4.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-4" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes4.activo" name="checkbox-4" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '1'">
                        <validation-provider name="mayo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Mayo:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes5.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes5.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-5" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes5.activo" name="checkbox-5" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '1'">
                        <validation-provider name="junio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Junio:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes6.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes6.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-6" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes6.activo" name="checkbox-6" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '1'">
                        <validation-provider name="julio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Julio:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes7.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes7.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-7" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes7.activo" name="checkbox-7" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '1'">
                        <validation-provider name="agosto" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Agosto:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes8.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes8.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-8" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes8.activo" name="checkbox-8" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '1'">
                        <validation-provider name="septiembre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Septiembre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes9.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes9.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-9" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes9.activo" name="checkbox-9" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '1'">
                        <validation-provider name="octubre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Octubre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes10.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes10.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-10" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes10.activo" name="checkbox-10" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '1'">
                        <validation-provider name="noviembre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Noviembre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes11.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes11.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-11" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes11.activo" name="checkbox-11" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '1'">
                        <validation-provider name="diciembre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Diciembre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes12.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes12.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-12" v-model="datosModificarIndicador.datosFrecuencia.mensual.mes12.activo" name="checkbox-12" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '2'">
                        <validation-provider name="1° bimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="1° Bimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre1.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre1.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-13" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre1.activo" name="checkbox-13" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '2'">
                        <validation-provider name="2° bimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="2° Bimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre2.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre2.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-14" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre2.activo" name="checkbox-14" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '2'">
                        <validation-provider name="3° bimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="3° Bimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre3.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre3.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-15" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre3.activo" name="checkbox-15" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '2'">
                        <validation-provider name="4° bimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="4° Bimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre4.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre4.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-16" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre4.activo" name="checkbox-16" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '2'">
                        <validation-provider name="5° bimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="5° Bimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre5.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre5.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-17" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre5.activo" name="checkbox-17" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '2'">
                        <validation-provider name="6° bimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="6° Bimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre6.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre6.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-18" v-model="datosModificarIndicador.datosFrecuencia.bimestral.bimestre6.activo" name="checkbox-18" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '3'">
                        <validation-provider name="1° trimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="1° Trimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.trimestral.trimestre1.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.trimestral.trimestre1.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-19" v-model="datosModificarIndicador.datosFrecuencia.trimestral.trimestre1.activo" name="checkbox-19" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '3'">
                        <validation-provider name="2° trimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="2° Trimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.trimestral.trimestre2.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.trimestral.trimestre2.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-20" v-model="datosModificarIndicador.datosFrecuencia.trimestral.trimestre2.activo" name="checkbox-20" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '3'">
                        <validation-provider name="3° trimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="3° Trimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.trimestral.trimestre3.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.trimestral.trimestre3.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-21" v-model="datosModificarIndicador.datosFrecuencia.trimestral.trimestre3.activo" name="checkbox-21" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosModificarIndicador.frecuencia == '3'">
                        <validation-provider name="4° trimestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="4° Trimestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.trimestral.trimestre4.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.trimestral.trimestre4.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-22" v-model="datosModificarIndicador.datosFrecuencia.trimestral.trimestre4.activo" name="checkbox-22" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6" v-if="datosModificarIndicador.frecuencia == '4'">
                        <validation-provider name="1° semestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="1° Semestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.semestral.semestre1.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.semestral.semestre1.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-23" v-model="datosModificarIndicador.datosFrecuencia.semestral.semestre1.activo" name="checkbox-23" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosModificarIndicador.frecuencia == '4'">
                        <validation-provider name="2° semestre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="2° Semestre:" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.semestral.semestre2.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.semestral.semestre2.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-24" v-model="datosModificarIndicador.datosFrecuencia.semestral.semestre2.activo" name="checkbox-24" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6" v-if="datosModificarIndicador.frecuencia == '5'">
                        <validation-provider name="anual" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group :label="'Anual - '+anioActual" class="mb-2">
                                <b-input-group>
                                    <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.anual.anio.valor1"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text>%</b-input-group-text>
                                    </b-input-group-append>
                                    <template v-if="datosModificarIndicador.valorReal == 2">
                                        <span class="slash-space"> / </span>
                                        <b-form-input size="md" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosModificarIndicador.datosFrecuencia.anual.anio.valor2"></b-form-input>
                                        <b-input-group-append>
                                            <b-input-group-text>%</b-input-group-text>
                                        </b-input-group-append>
                                    </template>
                                    <b-form-checkbox plain class="ml-2 mt-1" id="checkbox-25" v-model="datosModificarIndicador.datosFrecuencia.anual.anio.activo" name="checkbox-25" value="2" unchecked-value="1">Activo</b-form-checkbox>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observaciones" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosModificarIndicador.observaciones" :state="getValidationState(validationContext)" placeholder="Ingrese la observacion" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalModificarIndicador = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalModificarProceso" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo proceso </span>
            </h6>
            <CButtonClose @click="modalModificarProceso = false" class="text-white" />
        </template>
        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(modificarProceso)">
                <b-row>
                    <b-col lg="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosProceso.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <b-form-group label="Tipo Proceso: ">
                            <b-form-radio-group small plain v-model="datosProceso.tipoProceso" :options="radioTipoProceso"></b-form-radio-group>
                        </b-form-group>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="descripcion" :rules="{max:255}" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.descripcion" size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="referencias" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Referencias documentales:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.referenciasDocumentales" :state="getValidationState(validationContext)" placeholder="Ingrese las referencias" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="proveedores principales" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Proveedores Principales:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.proveedoresPrincipales" :state="getValidationState(validationContext)" placeholder="Ingrese los proveedores principales" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="proveedores" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Proveedores:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.proveedores" :state="getValidationState(validationContext)" placeholder="Ingrese los proveedores" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="entradas" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Entradas:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.entradas" :state="getValidationState(validationContext)" placeholder="Ingrese las entradas" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="responsable" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Responsable:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.responsable" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="recursos" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Recursos:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.recursos" :state="getValidationState(validationContext)" placeholder="Ingrese los recursos" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="clientes" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Clientes:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.clientes" :state="getValidationState(validationContext)" placeholder="Ingrese los clientes" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="salidas" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Salidas:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.salidas" :state="getValidationState(validationContext)" placeholder="Ingrese las salidas" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="registros" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Registros:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.registros" :state="getValidationState(validationContext)" placeholder="Ingrese los registros" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="inspecciones" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Inspecciones:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.inspecciones" :state="getValidationState(validationContext)" placeholder="Ingrese las inspecciones" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="Indicaciones / Riesgos" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Indicaciones / Riesgos:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.indicacionesRiesgos" :state="getValidationState(validationContext)" placeholder="Ingrese las indicaciones" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" size="md" variant="success" class="mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalModificarProceso = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import {
    CChartPie
} from '@coreui/vue-chartjs'
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {
        CChartPie
    },
    data() {
        return {
            labelEstadistica: ["Superado", "En tolerancia", "No alcanzado"],
            optionsEstadistica: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            datosActualizarProceso: {
                nombre: '',
                tipoProceso: 1,
                descripcion: '',
                referenciasDocumentales: '',
                proveedores: '',
                entradas: '',
                responsable: '',
                recursos: '',
                cliente: '',
                salidas: '',
                registros: '',
                inspecciones: '',
                indicadoresRiesgos: '',
            },
            modalActualizarProceso: false,
            modalRegistrarProceso: false,
            modalModificarProceso: false,
            radioTipoProceso: [{
                    value: "1",
                    text: "Estratégicos"
                },
                {
                    value: "2",
                    text: "Misionales"
                },
                {
                    value: "3",
                    text: "Apoyo"
                }
            ],

            datosProceso: {
                idProceso: '',
                idCliente: '',
                nombre: '',
                tipoProceso: 1,
                descripcion: '',
                referenciasDocumentales: '',
                proveedoresPrincipales: '',
                proveedores: '',
                entradas: '',
                responsable: '',
                recursos: '',
                clientes: '',
                salidas: '',
                registros: '',
                inspecciones: '',
                indicacionesRiesgos: '',
            },

            datosIndicador: {
                idProceso: this.$route.params.id,
                valorReal: 1,
                descripcionIndicador: '',
                idResponsableIndicador: null,
                idResponsableAprobacion: null,
                fechaInicio: moment().format('YYYY-MM-DD'),
                fechaFin: moment().format('YYYY-MM-DD'),
                condicion: null,
                valorFinalEsperado1: '',
                valorFinalEsperado2: '',
                tolerancia: '',
                frecuencia: null, //1 - mensual //2- bimetral //3-trimesttal //4 semestral //5 anual
                observaciones: '',
                datosFrecuencia: {
                    mensual: {
                        mes1: {
                            nombre: 'Enero',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes2: {
                            nombre: 'Febrero',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes3: {
                            nombre: 'Marzo',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes4: {
                            nombre: 'Abril',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes5: {
                            nombre: 'Mayo',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes6: {
                            nombre: 'Junio',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes7: {
                            nombre: 'Julio',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes8: {
                            nombre: 'Agosto',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes9: {
                            nombre: 'Septiembre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes10: {
                            nombre: 'Octubre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes11: {
                            nombre: 'Noviembre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes12: {
                            nombre: 'Diciembre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                    },
                    bimestral: {
                        bimestre1: {
                            nombre: '1° bimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        bimestre2: {
                            nombre: '2° bimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        bimestre3: {
                            nombre: '3° bimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        bimestre4: {
                            nombre: '4° bimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        bimestre5: {
                            nombre: '5° bimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        bimestre6: {
                            nombre: '6° bimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                    },
                    trimestral: {
                        trimestre1: {
                            nombre: '1° trimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        trimestre2: {
                            nombre: '2° trimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        trimestre3: {
                            nombre: '3° trimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        trimestre4: {
                            nombre: '4° trimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                    },
                    semestral: {
                        semestre1: {
                            nombre: '1° semestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        semestre2: {
                            nombre: '2° semestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                    },
                    anual: {
                        anio: {
                            nombre: 'Anual - 2021',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        }
                    }
                }
            },

            datosModificarIndicador: {
                idIndicador: '',
                idProceso: null,
                valorReal: 1,
                descripcionIndicador: '',
                idResponsableIndicador: null,
                idResponsableAprobacion: null,
                fechaInicio: '',
                fechaFin: '',
                condicion: null,
                valorFinalEsperado1: '',
                valorFinalEsperado2: '',
                tolerancia: '',
                frecuencia: null, //1 - mensual //2- bimetral //3-trimesttal //4 semestral //5 anual
                observaciones: '',
                idVerificador: '',
                datosFrecuencia: {
                    mensual: {
                        mes1: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Enero',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes2: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Febrero',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes3: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Marzo',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes4: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Abril',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes5: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Mayo',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes6: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Junio',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes7: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Julio',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes8: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Agosto',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes9: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Septiembre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes10: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Octubre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes11: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Noviembre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        mes12: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Diciembre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                    },
                    bimestral: {
                        bimestre1: {
                            idIndicadorFrecuencia: '',
                            nombre: '1° bimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        bimestre2: {
                            idIndicadorFrecuencia: '',
                            nombre: '2° bimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        bimestre3: {
                            idIndicadorFrecuencia: '',
                            nombre: '3° bimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        bimestre4: {
                            idIndicadorFrecuencia: '',
                            nombre: '4° bimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        bimestre5: {
                            idIndicadorFrecuencia: '',
                            nombre: '5° bimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        bimestre6: {
                            idIndicadorFrecuencia: '',
                            nombre: '6° bimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                    },
                    trimestral: {
                        trimestre1: {
                            idIndicadorFrecuencia: '',
                            nombre: '1° trimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        trimestre2: {
                            idIndicadorFrecuencia: '',
                            nombre: '2° trimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        trimestre3: {
                            idIndicadorFrecuencia: '',
                            nombre: '3° trimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        trimestre4: {
                            idIndicadorFrecuencia: '',
                            nombre: '4° trimestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                    },
                    semestral: {
                        semestre1: {
                            idIndicadorFrecuencia: '',
                            nombre: '1° semestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                        semestre2: {
                            idIndicadorFrecuencia: '',
                            nombre: '2° semestre',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        },
                    },
                    anual: {
                        anio: {
                            idIndicadorFrecuencia: '',
                            nombre: 'Anual - 2021',
                            valor1: '',
                            valor2: '',
                            activo: 1
                        }
                    }
                }
            },

            comboProcesos: [],
            comboFrecuencia: [{
                idFrecuencia: '1',
                descripcion: "Mensual"
            }, {
                idFrecuencia: '2',
                descripcion: "Bimestral"
            }, {
                idFrecuencia: '3',
                descripcion: "Trimestral"
            }, {
                idFrecuencia: '4',
                descripcion: "Semestral"
            }, {
                idFrecuencia: '5',
                descripcion: "Anual"
            }],

            comboCargoResponsable: [],

            comboCondicion: [{
                idCondicion: 1,
                descripcion: "Igual"
            }, {
                idCondicion: 2,
                descripcion: "Mayor o igual"
            }, {
                idCondicion: 3,
                descripcion: "Mayor"
            }, {
                idCondicion: 4,
                descripcion: "Menor o igual"
            }, {
                idCondicion: 5,
                descripcion: "Menor"
            }],

            radioValorReal: [{
                    text: 'Estático',
                    value: 1
                },
                {
                    text: 'Fórmula',
                    value: 2
                }
            ],

            listaIndicadores: [],
            camposIndicadores: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "proceso",
                    label: "Proceso",
                    class: "text-center",
                },
                {
                    key: "valorReal",
                    label: "Valor real",
                    class: "text-center",
                },
                {
                    key: "condicion",
                    label: "",
                    class: "text-center",
                },
                {
                    key: "valorFinalEsperado",
                    label: "Valor final esperado",
                    class: "text-center",
                },

                {
                    key: "frecuencia",
                    label: "Frecuencia",
                    class: "text-center",
                },
                {
                    key: "estado",
                    label: "Estado",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            filasTotales: 0,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            modalRegistrarIndicador: false,
            modalModificarIndicador: false,
            disabled: false,
            datosSession: {
                idCliente: ''
            },
            anioActual: moment().format('YYYY'),
            signos: {
                igual: '==',
                mayorIgual: '>=',
                mayor: '>',
                menorIgual: '<=',
                menor: '<'
            },
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        blurProceso() {
            this.computedForm.refs.proceso.validate();
        },
        blurModificarProceso() {
            this.computedForm2.refs.proceso_.validate();
        },

        blurResponsableIndicador() {
            this.computedForm.refs["responsable del indicador"].validate();
        },
        blurResponsableAprobacion() {
            this.computedForm.refs["responsable de la aprobación"].validate();
        },

        blurModificarResponsableIndicador() {
            this.computedForm2.refs["responsable del indicador"].validate();
        },
        blurModificarResponsableAprobacion() {
            this.computedForm2.refs["responsable de la aprobación"].validate();
        },
        abrirModalModificarIndicador(param) {
            let me = this;
            me.listarIndicadoresFrecuencias(param.item.idIndicador, param.item.frecuencia);
            me.datosModificarIndicador.idIndicador = param.item.idIndicador;
            me.datosModificarIndicador.idProceso = param.item.idProceso,
                me.datosModificarIndicador.valorReal = param.item.valorReal,
                me.datosModificarIndicador.descripcionIndicador = param.item.descripcionIndicador,
                me.datosModificarIndicador.idResponsableIndicador = param.item.idResponsableIndicador,
                me.datosModificarIndicador.idResponsableAprobacion = param.item.idResponsableAprobacion,
                me.datosModificarIndicador.fechaInicio = param.item.fechaInicio,
                me.datosModificarIndicador.fechaFin = param.item.fechaFin,
                me.datosModificarIndicador.condicion = param.item.condicion,
                me.datosModificarIndicador.valorFinalEsperado1 = param.item.valorFinalEsperado1,
                me.datosModificarIndicador.valorFinalEsperado2 = param.item.valorFinalEsperado2,
                me.datosModificarIndicador.tolerancia = param.item.tolerancia,
                me.datosModificarIndicador.frecuencia = param.item.frecuencia,
                me.datosModificarIndicador.observaciones = param.item.observaciones,
                me.datosModificarIndicador.idVerificador = param.item.frecuencia,

                me.modalModificarIndicador = true
        },
        listarIndicadores() {
            let me = this;
            let idProceso = this.$route.params.id;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-indicadores-proceso", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        idProceso: idProceso,
                    }
                })
                .then(function (response) {
                    me.listaIndicadores = response.data;
                    me.filasTotales = me.listaIndicadores.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarIndicadoresFrecuencias(idIndicador, frecuencia) {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-indicadores-frecuencia", {
                    params: {
                        idIndicador: idIndicador
                    }
                })
                .then(function (response) {
                    if (frecuencia == '1') {
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes1.idIndicadorFrecuencia = response.data[0].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes1.valor1 = response.data[0].valor1;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes1.valor2 = response.data[0].valor2;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes1.activo = response.data[0].activo;

                        me.datosModificarIndicador.datosFrecuencia.mensual.mes2.idIndicadorFrecuencia = response.data[1].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes2.valor1 = response.data[1].valor1;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes2.valor2 = response.data[1].valor2;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes2.activo = response.data[1].activo;

                        me.datosModificarIndicador.datosFrecuencia.mensual.mes3.idIndicadorFrecuencia = response.data[2].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes3.valor1 = response.data[2].valor1;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes3.valor2 = response.data[2].valor2;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes3.activo = response.data[2].activo;

                        me.datosModificarIndicador.datosFrecuencia.mensual.mes4.idIndicadorFrecuencia = response.data[3].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes4.valor1 = response.data[3].valor1;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes4.valor2 = response.data[3].valor2;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes4.activo = response.data[3].activo;

                        me.datosModificarIndicador.datosFrecuencia.mensual.mes5.idIndicadorFrecuencia = response.data[4].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes5.valor1 = response.data[4].valor1;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes5.valor2 = response.data[4].valor2;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes5.activo = response.data[4].activo;

                        me.datosModificarIndicador.datosFrecuencia.mensual.mes6.idIndicadorFrecuencia = response.data[5].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes6.valor1 = response.data[5].valor1;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes6.valor2 = response.data[5].valor2;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes6.activo = response.data[5].activo;

                        me.datosModificarIndicador.datosFrecuencia.mensual.mes7.idIndicadorFrecuencia = response.data[6].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes7.valor1 = response.data[6].valor1;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes7.valor2 = response.data[6].valor2;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes7.activo = response.data[6].activo;

                        me.datosModificarIndicador.datosFrecuencia.mensual.mes8.idIndicadorFrecuencia = response.data[7].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes8.valor1 = response.data[7].valor1;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes8.valor2 = response.data[7].valor2;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes8.activo = response.data[7].activo;

                        me.datosModificarIndicador.datosFrecuencia.mensual.mes9.idIndicadorFrecuencia = response.data[8].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes9.valor1 = response.data[8].valor1;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes9.valor2 = response.data[8].valor2;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes9.activo = response.data[8].activo;

                        me.datosModificarIndicador.datosFrecuencia.mensual.mes10.idIndicadorFrecuencia = response.data[9].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes10.valor1 = response.data[9].valor1;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes10.valor2 = response.data[9].valor2;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes10.activo = response.data[9].activo;

                        me.datosModificarIndicador.datosFrecuencia.mensual.mes11.idIndicadorFrecuencia = response.data[10].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes11.valor1 = response.data[10].valor1;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes11.valor2 = response.data[10].valor2;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes11.activo = response.data[10].activo;

                        me.datosModificarIndicador.datosFrecuencia.mensual.mes12.idIndicadorFrecuencia = response.data[11].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes12.valor1 = response.data[11].valor1;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes12.valor2 = response.data[11].valor2;
                        me.datosModificarIndicador.datosFrecuencia.mensual.mes12.activo = response.data[11].activo;
                    }
                    if (frecuencia == '2') {
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre1.idIndicadorFrecuencia = response.data[0].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre1.valor1 = response.data[0].valor1;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre1.valor2 = response.data[0].valor2;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre1.activo = response.data[0].activo;

                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre2.idIndicadorFrecuencia = response.data[1].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre2.valor1 = response.data[1].valor1;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre2.valor2 = response.data[1].valor2;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre2.activo = response.data[1].activo;

                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre3.idIndicadorFrecuencia = response.data[2].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre3.valor1 = response.data[2].valor1;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre3.valor2 = response.data[2].valor2;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre3.activo = response.data[2].activo;

                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre4.idIndicadorFrecuencia = response.data[3].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre4.valor1 = response.data[3].valor1;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre4.valor2 = response.data[3].valor2;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre4.activo = response.data[3].activo;

                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre5.idIndicadorFrecuencia = response.data[4].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre5.valor1 = response.data[4].valor1;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre5.valor2 = response.data[4].valor2;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre5.activo = response.data[4].activo;

                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre6.idIndicadorFrecuencia = response.data[5].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre6.valor1 = response.data[5].valor1;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre6.valor2 = response.data[5].valor2;
                        me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre6.activo = response.data[5].activo;
                    }
                    if (frecuencia == '3') {
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre1.idIndicadorFrecuencia = response.data[0].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre1.valor1 = response.data[0].valor1;
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre1.valor2 = response.data[0].valor2;
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre1.activo = response.data[0].activo;

                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre2.idIndicadorFrecuencia = response.data[1].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre2.valor1 = response.data[1].valor1;
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre2.valor2 = response.data[1].valor2;
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre2.activo = response.data[1].activo;

                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre3.idIndicadorFrecuencia = response.data[2].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre3.valor1 = response.data[2].valor1;
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre3.valor2 = response.data[2].valor2;
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre3.activo = response.data[2].activo;

                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre4.idIndicadorFrecuencia = response.data[3].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre4.valor1 = response.data[3].valor1;
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre4.valor2 = response.data[3].valor2;
                        me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre4.activo = response.data[3].activo;
                    }
                    if (frecuencia == '4') {
                        me.datosModificarIndicador.datosFrecuencia.semestral.semestre1.idIndicadorFrecuencia = response.data[0].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.semestral.semestre1.valor1 = response.data[0].valor1;
                        me.datosModificarIndicador.datosFrecuencia.semestral.semestre1.valor2 = response.data[0].valor2;
                        me.datosModificarIndicador.datosFrecuencia.semestral.semestre1.activo = response.data[0].activo;

                        me.datosModificarIndicador.datosFrecuencia.semestral.semestre2.idIndicadorFrecuencia = response.data[1].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.semestral.semestre2.valor1 = response.data[1].valor1;
                        me.datosModificarIndicador.datosFrecuencia.semestral.semestre2.valor2 = response.data[1].valor2;
                        me.datosModificarIndicador.datosFrecuencia.semestral.semestre2.activo = response.data[1].activo;
                    }
                    if (frecuencia == '5') {
                        me.datosModificarIndicador.datosFrecuencia.anual.anio.idIndicadorFrecuencia = response.data[0].idIndicadorFrecuencia;
                        me.datosModificarIndicador.datosFrecuencia.anual.anio.valor1 = response.data[0].valor1;
                        me.datosModificarIndicador.datosFrecuencia.anual.anio.valor2 = response.data[0].valor2;
                        me.datosModificarIndicador.datosFrecuencia.anual.anio.activo = response.data[0].activo;
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        modificarProceso() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-proceso",
                    me.datosProceso, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarProceso = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.modalModificarProceso = false;
                    me.consultarProceso();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        consultarProceso() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-proceso", {
                        params: {
                            idProceso: this.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    me.datosProceso.idProceso = response.data[0].idProceso;
                    me.datosProceso.idCliente = response.data[0].idCliente;
                    me.datosProceso.nombre = response.data[0].nombre;
                    me.datosProceso.tipoProceso = response.data[0].tipoProceso;
                    me.datosProceso.descripcion = response.data[0].descripcion;
                    me.datosProceso.referenciasDocumentales = response.data[0].referenciasDocumentales;
                    me.datosProceso.proveedoresPrincipales = response.data[0].proveedoresPrincipales;
                    me.datosProceso.proveedores = response.data[0].proveedores;
                    me.datosProceso.entradas = response.data[0].entradas;
                    me.datosProceso.responsable = response.data[0].responsable;
                    me.datosProceso.recursos = response.data[0].recursos;
                    me.datosProceso.clientes = response.data[0].clientes;
                    me.datosProceso.salidas = response.data[0].salidas;
                    me.datosProceso.registros = response.data[0].registros;
                    me.datosProceso.inspecciones = response.data[0].inspecciones;
                    me.datosProceso.indicacionesRiesgos = response.data[0].indicacionesRiesgos;

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarProceso() {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el proceso?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-proceso", {
                            idProceso: this.$route.params.id
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.$router.push({
                                name: 'Mapa de procesos',
                            });
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        registrarIndicador() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-indicador", {
                        datosIndicador: me.datosIndicador,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarIndicadores();
                    me.modalRegistrarIndicador = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        modificarIndicador() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-indicador", {
                        datosIndicador: me.datosModificarIndicador,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarIndicadores();
                    me.modalModificarIndicador = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarIndicador(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el indicador?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-indicador", {
                            idIndicador: param.item.idIndicador,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarIndicadores();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        listarProcesos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboProcesos = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboCargoResponsable = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        resetModalModificarProceso() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
            this.consultarProceso();
        },
        resetModalRegistrarIndicador() {
            let me = this;
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });

            //Aqui se tiene que limpiar todos los campos del objeto "datosIndicador"
            me.datosIndicador.valorReal = 1;
            me.datosIndicador.condicion = null;

            me.datosIndicador.descripcionIndicador = '';
            me.datosIndicador.idResponsableIndicador = null;
            me.datosIndicador.idResponsableAprobacion = null;

            me.datosIndicador.fechaInicio = moment().format('YYYY-MM-DD');
            me.datosIndicador.fechaFin = moment().format('YYYY-MM-DD');
            me.datosIndicador.valorFinalEsperado1 = '';
            me.datosIndicador.valorFinalEsperado2 = '';
            me.datosIndicador.tolerancia = '';
            me.datosIndicador.frecuencia = null;
            me.datosIndicador.observaciones = '';
            me.resetDatosFrecuencia();
        },

        resetDatosFrecuencia() {
            let me = this;
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            me.datosIndicador.datosFrecuencia.mensual.mes1.valor1 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes1.valor2 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes1.activo = 1;

            me.datosIndicador.datosFrecuencia.mensual.mes2.valor1 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes2.valor2 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes2.activo = 1;

            me.datosIndicador.datosFrecuencia.mensual.mes3.valor1 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes3.valor2 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes3.activo = 1;

            me.datosIndicador.datosFrecuencia.mensual.mes4.valor1 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes4.valor2 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes4.activo = 1;

            me.datosIndicador.datosFrecuencia.mensual.mes5.valor1 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes5.valor2 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes5.activo = 1;

            me.datosIndicador.datosFrecuencia.mensual.mes6.valor1 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes6.valor2 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes6.activo = 1;

            me.datosIndicador.datosFrecuencia.mensual.mes7.valor1 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes7.valor2 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes7.activo = 1;

            me.datosIndicador.datosFrecuencia.mensual.mes8.valor1 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes8.valor2 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes8.activo = 1;

            me.datosIndicador.datosFrecuencia.mensual.mes9.valor1 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes9.valor2 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes9.activo = 1;

            me.datosIndicador.datosFrecuencia.mensual.mes10.valor1 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes10.valor2 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes10.activo = 1;

            me.datosIndicador.datosFrecuencia.mensual.mes11.valor1 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes11.valor2 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes11.activo = 1;

            me.datosIndicador.datosFrecuencia.mensual.mes12.valor1 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes12.valor2 = '';
            me.datosIndicador.datosFrecuencia.mensual.mes12.activo = 1;

            me.datosIndicador.datosFrecuencia.bimestral.bimestre1.valor1 = '';
            me.datosIndicador.datosFrecuencia.bimestral.bimestre1.valor2 = '';
            me.datosIndicador.datosFrecuencia.bimestral.bimestre1.activo = 1;

            me.datosIndicador.datosFrecuencia.bimestral.bimestre2.valor1 = '';
            me.datosIndicador.datosFrecuencia.bimestral.bimestre2.valor2 = '';
            me.datosIndicador.datosFrecuencia.bimestral.bimestre2.activo = 1;

            me.datosIndicador.datosFrecuencia.bimestral.bimestre3.valor1 = '';
            me.datosIndicador.datosFrecuencia.bimestral.bimestre3.valor2 = '';
            me.datosIndicador.datosFrecuencia.bimestral.bimestre3.activo = 1;

            me.datosIndicador.datosFrecuencia.bimestral.bimestre4.valor1 = '';
            me.datosIndicador.datosFrecuencia.bimestral.bimestre4.valor2 = '';
            me.datosIndicador.datosFrecuencia.bimestral.bimestre4.activo = 1;

            me.datosIndicador.datosFrecuencia.bimestral.bimestre5.valor1 = '';
            me.datosIndicador.datosFrecuencia.bimestral.bimestre5.valor2 = '';
            me.datosIndicador.datosFrecuencia.bimestral.bimestre5.activo = 1;

            me.datosIndicador.datosFrecuencia.bimestral.bimestre6.valor1 = '';
            me.datosIndicador.datosFrecuencia.bimestral.bimestre6.valor2 = '';
            me.datosIndicador.datosFrecuencia.bimestral.bimestre6.activo = 1;

            me.datosIndicador.datosFrecuencia.trimestral.trimestre1.valor1 = '';
            me.datosIndicador.datosFrecuencia.trimestral.trimestre1.valor2 = '';
            me.datosIndicador.datosFrecuencia.trimestral.trimestre1.activo = 1;

            me.datosIndicador.datosFrecuencia.trimestral.trimestre2.valor1 = '';
            me.datosIndicador.datosFrecuencia.trimestral.trimestre2.valor2 = '';
            me.datosIndicador.datosFrecuencia.trimestral.trimestre2.activo = 1;

            me.datosIndicador.datosFrecuencia.trimestral.trimestre3.valor1 = '';
            me.datosIndicador.datosFrecuencia.trimestral.trimestre3.valor2 = '';
            me.datosIndicador.datosFrecuencia.trimestral.trimestre3.activo = 1;

            me.datosIndicador.datosFrecuencia.trimestral.trimestre4.valor1 = '';
            me.datosIndicador.datosFrecuencia.trimestral.trimestre4.valor2 = '';
            me.datosIndicador.datosFrecuencia.trimestral.trimestre4.activo = 1;

            me.datosIndicador.datosFrecuencia.semestral.semestre1.valor1 = '';
            me.datosIndicador.datosFrecuencia.semestral.semestre1.valor2 = '';
            me.datosIndicador.datosFrecuencia.semestral.semestre1.activo = 1;

            me.datosIndicador.datosFrecuencia.semestral.semestre2.valor1 = '';
            me.datosIndicador.datosFrecuencia.semestral.semestre2.valor2 = '';
            me.datosIndicador.datosFrecuencia.semestral.semestre2.activo = 1;

            me.datosIndicador.datosFrecuencia.anual.anio.valor1 = '';
            me.datosIndicador.datosFrecuencia.anual.anio.valor2 = '';
            me.datosIndicador.datosFrecuencia.anual.anio.activo = 1;
        },

        resetDatosFrecuenciaModificar() {
            let me = this;
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            me.datosModificarIndicador.datosFrecuencia.mensual.mes1.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes1.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes1.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.mensual.mes2.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes2.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes2.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.mensual.mes3.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes3.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes3.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.mensual.mes4.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes4.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes4.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.mensual.mes5.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes5.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes5.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.mensual.mes6.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes6.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes6.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.mensual.mes7.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes7.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes7.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.mensual.mes8.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes8.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes8.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.mensual.mes9.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes9.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes9.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.mensual.mes10.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes10.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes10.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.mensual.mes11.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes11.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes11.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.mensual.mes12.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes12.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.mensual.mes12.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre1.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre1.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre1.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre2.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre2.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre2.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre3.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre3.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre3.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre4.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre4.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre4.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre5.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre5.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre5.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre6.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre6.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.bimestral.bimestre6.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre1.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre1.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre1.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre2.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre2.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre2.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre3.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre3.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre3.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre4.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre4.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.trimestral.trimestre4.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.semestral.semestre1.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.semestral.semestre1.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.semestral.semestre1.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.semestral.semestre2.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.semestral.semestre2.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.semestral.semestre2.activo = 1;

            me.datosModificarIndicador.datosFrecuencia.anual.anio.valor1 = '';
            me.datosModificarIndicador.datosFrecuencia.anual.anio.valor2 = '';
            me.datosModificarIndicador.datosFrecuencia.anual.anio.activo = 1;
        },

        resetModalModificarIndicador() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
        obtenerData() {
            this.dataSetsEstadistica[0].data.push(7, 4, 6);
            this.cargaPieChart = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedForm2() {
            return this.$refs.observer2;
        },
        dataSetsEstadistica() {
            return [{
                borderWidth: 3,
                backgroundColor: [
                    '#88c423c7',
                    '#fdcd60cc',
                    '#f16553d4'
                ],
                data: [this.listaIndicadores.filter(x => x.estado == 2).length, 0, this.listaIndicadores.filter(x => x.estado == 1).length]
            }]
        }
    },
    watch: {
        modalRegistrarIndicador: function (val) {
            if (val == false) {
                this.resetModalRegistrarIndicador();
            }
        },
        modalModificarIndicador: function (val) {
            if (val == false) {
                this.resetModalModificarIndicador();
            }
        },
        modalModificarProceso: function (val) {
            if (val == false) {
                this.resetModalModificarProceso();
            }
        },
    },
    mounted() {

        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarIndicadores();
            this.obtenerData();
            this.listarProcesos();
            this.listarPuestosTrabajo();
            this.consultarProceso();

        }
    }

}
</script>
